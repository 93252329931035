import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/logotnxtlogin.png";
import { ScaleLoader } from "react-spinners";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";

const Register = () => {
  const [FormData, setData] = useState({
    user: "",
    password: "",
    re_password: "",
    email: "",
    type: "",
    address: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const ValidateData = {};

    if (FormData.user.trim() === "") {
      ValidateData.userErr = "User name is required";
      toast.error(ValidateData.userErr, { position: "top-center" });
    } else if (FormData.password.trim() === "") {
      ValidateData.passwordErr = "Password is required";
      toast.error(ValidateData.passwordErr, { position: "top-center" });
    } else if (FormData.password.length < 8) {
      ValidateData.passwordErr = "Password must be at least 8 characters long";
      toast.warning(ValidateData.passwordErr, { position: "top-center" });
    } else if (!/\d/.test(FormData.password)) {
      ValidateData.passwordErr = "Password must contain at least one digit ";
      toast.warning(ValidateData.passwordErr, { position: "top-center" });
    } else if (!/[a-zA-Z]/.test(FormData.password)) {
      ValidateData.passwordErr = "Password must contain at least one letter";
      toast.warning(ValidateData.passwordErr, { position: "top-center" });
    } else if (FormData.password !== FormData.re_password) {
      ValidateData.re_passwordErr = "Confirm password is wrong!";
      toast.error(ValidateData.re_passwordErr, { position: "top-center" });
    } else if (FormData.email.trim() === "") {
      ValidateData.emailErr = "Email is required";
      toast.error(ValidateData.emailErr, { position: "top-center" });
    } else if (FormData.type.trim() === "") {
      ValidateData.typeErr = "User type is required";
      toast.error(ValidateData.typeErr, { position: "top-center" });
    } else if (FormData.address.trim() === "") {
      ValidateData.addressErr = "Address is required";
      toast.error(ValidateData.addressErr, { position: "top-center" });
    }

    if (Object.keys(ValidateData).length === 0) {
      console.log(FormData);
      const { user, email, password, re_password, type, address } = FormData;
      setLoading(true);
      try {
        const data = await fetch("http://localhost:8010/api/user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user,
            email,
            password,
            re_password,
            type,
            address,
          }),
        });

        const res = await data.json();
        console.log(res.status);

        if (res.status === 300) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: res.msg,
            text: "Please check your mail to verify the Account!",
          });

          setData((prevState) => ({
            ...prevState,
            user: "",
            email: "",
            password: "",
            re_password: "",
            type: "",
            address: "",
          }));
        } else if (res.status === 422) {
          setLoading(false);
          Swal.fire({
            icon: "warning",
            title: "Email-Id is already exists",
            text: "Register Not Successfully!",
          });
        } else if (res.status === 501) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Invalid Email-Id",
            text: res.msg,
          });
        } else if (res.status === 424) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Something Went Wrong!",
          });
        }
      } catch {
        setLoading(false);
        Swal.fire({
          icon: "question",
          title: "502 Bad Gateway",
        });
      }
    }
  };

  return (
    <div className="wrapper ">
      {loading && (
        <div className="blur-background">
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ScaleLoader color={"#26ADB7"} size={60} />
          </span>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="d-flex flex-column justify-content-center align-items-center  w-100"
      >
        <div className="form-group text-center">
          <div>
            <img
              src={logo}
              alt="Logo"
              width="300px"
              height="250px"
              style={{ marginBottom: "-20%", marginTop: "-23%" }}
            />
            <h2 style={{ fontFamily: "segoeui" }} className="text-center mb-2">
              <b>Register Page</b>
            </h2>
          </div>

          <input
            type="text"
            placeholder="UserName*"
            className="form-control  mb-3 rounded"
            name="user"
            value={FormData.user}
            onChange={handleChange}
            required
          />

          <input
            type="password"
            placeholder="Password"
            className="form-control mb-3 rounded"
            name="password"
            value={FormData.password}
            onChange={handleChange}
            required
          />

          <input
            type="password"
            placeholder="Re-Password"
            className="form-control  mb-3 rounded"
            name="re_password"
            value={FormData.re_password}
            onChange={handleChange}
            required
          />

          <input
            type="email"
            placeholder="Email*"
            className="form-control mb-3 rounded"
            name="email"
            value={FormData.email}
            onChange={handleChange}
            required
          />
          <Form.Select
            as="select"
            name="type"
            className=" rounded mb-3"
            value={FormData.type}
            onChange={handleChange}
            required
          >
            <option value="">Select the User Type*</option>
            <option value="Personal">Personal</option>
            <option value="Organization">Organization</option>
          </Form.Select>

          <textarea
            className="form-control rounded mb-2 "
            placeholder="Address*"
            name="address"
            value={FormData.address}
            onChange={handleChange}
            required
          />
          <button
            type="submit"
            className="btn btn-success rounded px-3 p-1 my-2"
            onClick={handleSubmit}
          >
            Register
          </button>
          <p>
            Do You have an account! <Link to={"/login"}>Login</Link>
          </p>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Register;
