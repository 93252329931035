import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Careers from "./pages/Careers";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import CareerRegister from "./pages/CareerRegister";
import NavBar from "./components/NavBar";
import OurServices from "./pages/OurServices";
import Home from "./pages/Home";
import ItConsulting from "./pages/ItConsulting";
import ItOutSourcing from "./pages/ItOutSourcing";
import SoftwareDev from "./pages/SoftwareDev";
import Foundation from "./pages/Foundation";
import Contact from "./pages/ContactUs";
import Product from "./pages/Product";
import OurStories from "./pages/OurStory";
import Register from "./pages/Register";
import Login from "./pages/Login";
import MailVerified from "./components/MailVerification";

function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/services" element={<OurServices />} />
          <Route path="/careerRegistration" element={<CareerRegister />} />
          <Route path="/itConsulting" element={<ItConsulting />} />
          <Route path="/itOutSourcing" element={<ItOutSourcing />} />
          <Route path="/softwareDevelopment" element={<SoftwareDev />} />
          <Route path="/foundation" element={<Foundation />} />
          <Route path="/contactUs" element={<Contact />} />
          <Route path="/Products" element={<Product />} />
          <Route path="/ourStory" element={<OurStories />} />
          <Route path="/verified/:role/:status" element={<MailVerified />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
