import React, { useState } from "react";
import ourvission from "../images/OurVission.png";
import ourmission from "../images/ourMission.png";

const About = () => {
  const [content, setContent] = useState(false);
  const view = () => {
    setContent(true);
  };
  const hide = () => {
    setContent(false);
  };

  return (
    <div>
      <div className="container">
        <div className="row d-flex justify-centent-evenly">
          <div className="col-md-6 ">
            <h1 className="my-4" style={{ fontSize: "50px" }}>
              <strong>About TNXT</strong>
            </h1>
            <div className="justify-content-center"></div>
            <h2>Our Vision</h2>
            <p className="lead my-4" style={{ textAlign: "justify" }}>
              At TNXT India Private Limited, our vision is to revolutionize the
              B2B and SaaS industry by empowering businesses with cutting-edge
              technology solutions. We envision a future where businesses
              seamlessly leverage innovative software and services to unlock
              their full potential, drive growth and achieve sustainable
              success. Through our visionary approach, we aim to be the catalyst
              that transforms the way companies operate, collaborate and thrive
              in the digital era.
            </p>

            <div className="d-flex justify-content-left">
              <button
                className="btn rounded text-white p-2 px-4"
                style={{ backgroundColor: "#26ADB7", border: "none" }}
                onClick={view}
              >
                Read More..
              </button>
            </div>
          </div>

          <div
            className=" col-md-6 "
            style={{ marginTop: "88px", marginBottom: "88px" }}
          >
            <img width="110%" src={ourvission} alt="Our Vission"></img>
          </div>

          <div className="">
            {content && (
              <div>
                <div className="row ">
                  <h3 className="my-4 text-center">TNXT Mission </h3>

                  <p
                    className="lead text-center "
                    style={{ textAlign: "justify" }}
                  >
                    Our mission is to empower businesses with transformative B2B
                    and SaaS solutions that drive efficiency, innovation, and
                    competitiveness.{" "}
                  </p>

                  <div className="col-md-6  ">
                    <img width="100%" src={ourmission} alt="our mission"></img>
                  </div>

                  <div className="col-md-6 ">
                    <p style={{ textAlign: "justify" }}>
                      <b className="fw-bold " style={{ textAlign: "justify" }}>
                        Delivering Exceptional Value:
                      </b>{" "}
                      We strive to provide unparalleled value to our clients
                      through our innovative software and services. Our mission
                      is to deliver solutions that not only meet their current
                      needs but also anticipate future challenges and help them
                      stay ahead in their industry.<br></br>
                      <br></br>
                      <b className="fw-bold " style={{ textAlign: "justify" }}>
                        Fostering Growth and Scalability:
                      </b>{" "}
                      We understand the importance of scalability and growth for
                      businesses. Our mission is to provide scalable B2B and
                      SaaS solutions that adapt to the evolving needs of our
                      clients. We aim to be their strategic partner, empowering
                      them to scale their operations, enter new markets, and
                      achieve sustainable growth.
                      <br />
                      <br></br>
                      <b className="fw-bold" style={{ textAlign: "justify" }}>
                        Driving Digital Transformation:
                      </b>{" "}
                      In an increasingly digital world, we are dedicated to
                      driving digital transformation for businesses. Our mission
                      is to help companies embrace new technologies, optimize
                      their processes, and leverage data-driven insights to make
                      informed decisions and drive business success.
                      <br />
                      <br></br>
                      <b className="fw-bold" style={{ textAlign: "justify" }}>
                        Ensuring Seamless Integration:
                      </b>{" "}
                      We recognize that businesses rely on a multitude of
                      software and systems. Our mission is to ensure seamless
                      integration of our B2B and SaaS solutions with existing
                      infrastructures, workflows, and applications. We strive to
                      provide a unified experience that enhances productivity
                      and simplifies operations.
                      <br />
                      <br></br>
                      <b className="fw-bold" style={{ textAlign: "justify" }}>
                        Building Strong Partnerships:
                      </b>{" "}
                      Collaboration and partnership are at the heart of our
                      mission. We believe in building strong relationships with
                      our clients, understanding their unique needs, and working
                      together to achieve mutual success. We also foster
                      strategic partnerships with technology providers and
                      industry experts to create comprehensive solutions that
                      address complex challenges.
                      <br />
                      <br></br>
                      <b className="fw-bold" style={{ textAlign: "justify" }}>
                        Enabling Customer Success:
                      </b>{" "}
                      Our ultimate mission is to enable the success of our
                      clients. We are committed to providing exceptional
                      customer support, ongoing training, and proactive guidance
                      to ensure that our clients achieve their desired outcomes.
                      We measure our success by the success of our clients.
                      <br></br>
                    </p>

                    <div className="d-flex justify-content-center">
                      <button
                        className="btn rounded text-white p-2 px-4 "
                        style={{ backgroundColor: "#26ADB7", border: "none" }}
                        onClick={hide}
                      >
                        Read less..
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
