import React from "react";
import pic1 from "../images/ConsultingNew.svg";
import pic2 from "../images/OutsourcingNew.png";
import pic3 from "../images/DevelopmentNew.png";
import map from "../images/World Map.svg";
import calender from "../images/calender.png";
import product from "../images/product.png";
import smile from "../images/smile.png";
import stars from "../images/stars.svg";
import logo1 from "../images/logo 1.png";
import logo2 from "../images/logo 2.png";
import logo5 from "../images/KidsQatarRounded.png";
import logo3 from "../images/npr_logo.png";
import logo4 from "../images/Janus.svg";
import logo6 from "../images/NPRLogo.png";
import logo7 from "../images/Victorlogo.png";
import logo8 from "../images/VFlogo.png";
import logo9 from "../images/ATLogo.png";

import kidsqatar from "../images/Kids Qatar Logo.svg";
import kidsqatarProduct from "../images/KidsQatarProduct.png";
import employee from "../images/EmployeeProduct2.png";
import socialMedia from "../images/socialmedia.png";
import epicX from "../images/epicX.png";
import logistics from "../images/LogisticsProduct.png";
import visitor from "../images/VisitorProduct.png";
import traveldesk from "../images/TravelDeskProduct.png";
import tnxt from "../images/tnxt1.png";
import langingBg from "../images/Landing Page BG.svg";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="">
        <div className="row mt-3 d-flex justify-centent-evenly">
          <div
            className="col-md-6"
            style={{ marginTop: "88px", marginBottom: "88px" }}
          >
            <div className="m-3">
              <h1 className="" style={{ fontSize: "42px" }}>
                <strong>
                  WELCOME TO <img src={tnxt} width="18%"></img> INDIA PRIVATE
                  LIMITED{" "}
                </strong>{" "}
              </h1>

              <h1 className="mt-4" style={{ fontSize: "40px" }}>
                Innovation In
                <b style={{ color: "#FBD111" }}> E</b>
                <b style={{ color: "#F69D29" }}>v</b>
                <b style={{ color: "#ED654C" }}>e</b>
                <b style={{ color: "#26ADB7" }}>r</b>
                <b style={{ color: "#97D677" }}>y</b> Step
              </h1>
              <br></br>
              <Link
                to="/contactUs"
                onClick={handleClick}
                className="text-decoration-none text-dark"
              >
                {" "}
                <button
                  className="btn rounded-2 "
                  style={{ backgroundColor: "#26ADB7" }}
                >
                  <h5 className="m-1 text-white">Contact Us</h5>
                </button>
              </Link>
            </div>
          </div>
          <div className=" col-md-6  ">
            <img width="100%" height="100%" src={langingBg}></img>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ marginTop: "75px" }}>
        <div className="row">
          <div className="d-flex flex-column align-items-center">
            <h1 style={{ fontSize: "50px" }}>Our Services</h1>
            <p className="lead text-center">
              <b>
                "Take your business to new heights with our professional
                services"
              </b>
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-4 mb-5 pb-5 pt-2">
        <div className="row m-1 d-flex justify-content-around">
          <div
            className="col-md-3 mt-3  d-flex flex-column align-items-center justify-content-center"
            style={{
              boxShadow:
                "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
            }}
          >
            <Link
              to="/itConsulting"
              onClick={handleClick}
              className="text-decoration-none text-dark"
            >
              <img src={pic1} width="100%"></img>
              <p className="text-center fw-bold">Consulting</p>
            </Link>
          </div>
          <div
            className="col-md-3 mt-3 d-flex flex-column align-items-center justify-content-center"
            style={{
              boxShadow:
                "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
            }}
          >
            <Link
              to="/itOutSourcing"
              onClick={handleClick}
              className="text-decoration-none text-dark"
            >
              <img src={pic2} className="" width="100%"></img>
              <p className="text-center fw-bold">IT Outsourcing</p>
            </Link>
          </div>

          <div
            className="col-md-3 mt-3  pl-2 d-flex flex-column align-items-center justify-content-center"
            style={{
              boxShadow:
                "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
            }}
          >
            <Link
              to="/softwareDevelopment"
              onClick={handleClick}
              className="text-decoration-none text-dark"
            >
              <img src={pic3} width="100%"></img>
              <p className="text-center fw-bold mt-3">Software Development</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "75px" }}>
        {/* <div className='row'>
            <div className='d-flex flex-column mb-3 align-items-center'>
                <h1 style={{ fontSize: "50px" }}>Our Products</h1>
            </div>
        </div>
        <div className='mt-5 mb-5'>
            <Carousel responsive={responsive}>
                <div className='d-flex justify-content-center'>
                <div className='card d-flex align-items-center w-75' style={{
                background: 'linear-gradient(135deg, #FFF 0%, #EDEDED 100%)',
                boxShadow: '2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset'
            }}>
                <div className='m-3 d-flex justify-content-center align-items-center'>
                    <img className='rounded-circle p-2' src={kidsqatarProduct} width="109%" height="100%"></img>
                    </div>
                    <p>Kids Qatar</p>
                </div>
                </div>
                <div className='d-flex justify-content-center' >
                <div className='card d-flex  align-items-center w-75' style={{
                background: 'linear-gradient(135deg, #FFF 0%, #EDEDED 100%)',
                boxShadow: '2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset'
            }}>
                <div className='m-3 d-flex justify-content-center align-items-center'>
                    <img className='' src={socialMedia} width="95%" ></img>
                    </div>  
                    <p>Social Media Analysis</p>
                </div>
                </div>
                <div className='d-flex justify-content-center'>
                <div className='card d-flex  align-items-center w-75' style={{
                background: 'linear-gradient(135deg, #FFF 0%, #EDEDED 100%)',
                boxShadow: '2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset'
            }}>
                      <div className='m-3 d-flex justify-content-center align-items-center'>
                    <img className='rounded-circle' src={employee} width="93%"></img>
                    </div>
                    <p>Employee Management</p>
                </div>
                </div>
                <div className='d-flex justify-content-center'>
                <div className='card d-flex  align-items-center w-75' style={{
                background: 'linear-gradient(135deg, #FFF 0%, #EDEDED 100%)',
                boxShadow: '2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset'
            }}>
                <div className='m-3 d-flex justify-content-center align-items-center'>
                    <img className='product--image' src={epicX} width="95%"></img>
                    </div>
                    <p>Epic-X</p>
                </div>
                </div>
                <div className='d-flex justify-content-center'>
                <div className='card d-flex  align-items-center w-75' style={{
                background: 'linear-gradient(135deg, #FFF 0%, #EDEDED 100%)',
                boxShadow: '2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset'
            }}>
                  <div className='m-3 d-flex justify-content-center align-items-center rounded-circle' style={{backgroundColor:"white"}}>
                    
                    <img src={logistics} className=" "  style={{margin:"9px"}} width="90%" height="90%"></img>
                  
                    </div>
                    <p>Logistics Solutions</p>
                </div>
                </div>
                <div className='d-flex justify-content-center'>
                <div className='card d-flex  align-items-center w-75' style={{
                background: 'linear-gradient(135deg, #FFF 0%, #EDEDED 100%)',
                boxShadow: '2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset'
            }}>
                  <div className='m-3 d-flex justify-content-center align-items-center  rounded-circle' style={{backgroundColor:"white"}}>
               
                    <img src={visitor} className=" " style={{margin:"7px"}} width="90%" height="90%"></img>
                 
                    </div>
                    <p>Visitor Management</p>
                </div>
                </div>
                <div className='d-flex justify-content-center'>
                <div className='card d-flex  align-items-center w-75' style={{
                background: 'linear-gradient(135deg, #FFF 0%, #EDEDED 100%)',
                boxShadow: '2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset'
            }}>
                  <div className='m-3 d-flex justify-content-center align-items-center rounded-circle' style={{backgroundColor:"white"}} >
                    <img src={traveldesk} className="" style={{margin:"13px"}} width="90%" height="90%"></img>
                    </div>
                    <p>Travel Desk Solutions</p>
                </div>
                </div>
            </Carousel>
        </div> */}
      </div>
      <div className="row">
        <div className="d-flex  flex-column align-items-center">
          <h1 className="mt-3" style={{ fontSize: "50px" }}>
            Our Missions
          </h1>
          <p className="lead text-center ml-5  me-5">
            <b>
              "Together, let's work towards achieving our mission and changing
              lives Discover how you can play a vital role in our mission's
              success"
            </b>
          </p>
        </div>
      </div>
      <div className="container-fluid mt-5  pb-5 pt-3">
        <div className="row d-flex justify-content-around">
          <div className="col-md-3  d-flex flex-column align-items-center justify-content-center">
            <img src={smile} className="m-4" width="100px"></img>
            <h1 className="fw-bold">10+</h1>
            <p className="text-center fw-bold">Happy Client's</p>
          </div>
          <div className="col-md-3 d-flex flex-column align-items-center justify-content-center">
            <img src={calender} className="m-4" width="100px"></img>
            <h1 className="fw-bold">04</h1>
            <p className="text-center fw-bold">Year's of Experience</p>
          </div>
          {/* <div className='col-md-3  d-flex flex-column align-items-center justify-content-center'>
                        <img src={product} className='m-4' width="100px"></img>
                        <h1 className='fw-bold'>05</h1>
                        <p className='text-center fw-bold'>Our Products</p>
                    </div> */}
          <div className="col-md-3  d-flex flex-column align-items-center justify-content-center">
            <img src={map} className="m-4" width="200px"></img>
            <h1 className="fw-bold">04</h1>
            <p className="text-center fw-bold">Countries Served</p>
          </div>
        </div>
      </div>
      {/* <div className='container mb-5' >
                <div className='row '>
                    <div className='d-flex flex-column mt-5  align-items-center'>
                        <h1 style={{ fontSize: "50px" }}>Our Clients</h1>
                        <p className='lead text-center'><b>"Start a rewarding partnership with us and let us exceed your expectations"</b></p>
                    </div>
                </div>
                <div className='row mb-5 d-flex mt-3 justify-content-center'>
                    <div className='col-md-2 d-flex mt-2 justify-content-center'>
                        <img src={logo5} width="100%"></img>
                    </div>
                    <div className='col-md-2 d-flex mt-2  justify-content-center'>
                        <img src={logo2} width="100%"></img>
                    </div>
                    <div className='col-md-2 d-flex mt-2  justify-content-center'>
                        <img src={logo1} width="100%"></img>
                    </div>
                    <div className='col-md-2 d-flex mt-2  justify-content-center'>
                        <img src={logo4} width="100%"></img>
                    </div>
                    <div className='col-md-2 d-flex mt-2 justify-content-center'>
                        <img src={logo6} width="100%"></img>
                    </div>
                    <div className='col-md-2 d-flex mt-2  justify-content-center'>
                        <img src={logo7} width="100%"></img>
                    </div>
                    <div className='col-md-2 d-flex mt-2  justify-content-center'>
                        <img src={logo8} width="100%"></img>
                    </div>
                    <div className='col-md-2 d-flex mt-2  justify-content-center'>
                        <img src={logo9} width="100%"></img>
                    </div>
                </div>
            </div> */}
      {/* <div className='container-fluid mb-4 '>
                <div className='row mt-5 mb-3'>
                    <h1 className='text-center mt-5' style={{ fontSize: "50px" }}>Clients Feedback</h1>
                </div>
                <div className='row '>
                    <div className='col-md-3 mt-4 d-flex justify-content-center'>
                        <div className='border' style={{ height: "388px", width: "301px" }}>
                            <div className='mt-4'>
                                <p className='text-center p-4'>
                                    "Exceptional web development skills created a stunning and modern website" </p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="275" viewBox="0 0 331 205" fill="none">
                                <path d="M330.006 16C188.003 149.5 69.0031 -56.4997 0 16V204.445H330.006V16Z" fill="#ED654C" />
                                <defs>
                                    <clipPath id="circleClip">
                                        <circle cx="165" cy="50" r="50" />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#circleClip)">
                                    <rect x="116" y="0" width="100" height="100" fill="white" />
                                    <image href={logo5} x="115" y="0" width="100" height="100" />
                                </g>
                                <circle cx="165" cy="50" r="50" fill="none" stroke="white" strokeWidth="3" />
                                <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="25" fontWeight="bold">
                                    Kids Qatar
                                </text>
                                <image href={stars} x="32%" y="60%" width="120" height="120" />
                            </svg>
                        </div>
                    </div>

                    <div className='col-md-3 mt-4 d-flex justify-content-center'>
                        <div className='border' style={{ height: "388px", width: "301px" }}>
                            <div className='mt-4'>
                                <p className='text-center p-4'>
                                    "Impressive user experience designed a seamless and intuitive interface"  </p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="275" viewBox="0 0 331 205" fill="none">
                                <path d="M330.006 16C188.003 149.5 69.0031 -56.4997 0 16V204.445H330.006V16Z" fill="#F69D29" />
                                <defs>
                                    <clipPath id="circleClip">
                                        <circle cx="165" cy="50" r="50" />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#circleClip)">
                                    <rect x="116" y="0" width="100" height="100" fill="white" />

                                    <image href={logo2} x="104" y="-6" width="120" height="120" />
                                </g>
                                <circle cx="165" cy="50" r="50" fill="none" stroke="white" strokeWidth="3" />

                                <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="25" fontWeight="bold">
                                    Brain & Spine Secrets
                                </text>

                                <image href={stars} x="32%" y="60%" width="120" height="120" />
                            </svg>
                        </div>
                    </div>

                    <div className='col-md-3 mt-4 d-flex justify-content-center'>
                        <div className='border' style={{ height: "388px", width: "301px" }}>
                            <div className='mt-4'>
                                <p className='text-center p-4'>
                                    "The team's dedication to improve work environment make it filling the role" </p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="275" viewBox="0 0 331 205" fill="none">
                                <path d="M330.006 16C188.003 149.5 69.0031 -56.4997 0 16V204.445H330.006V16Z" fill="#26ADB7" />
                                <defs>
                                    <clipPath id="circleClip">
                                        <circle cx="165" cy="50" r="50" />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#circleClip)" >
                                    <rect x="116" y="0" width="100" height="100" fill="white" />

                                    <image href={logo1} x="108" y="-12" width="120" height="120" />
                                </g>
                                <circle cx="165" cy="50" r="50" fill="none" stroke="white" strokeWidth="3" />
                                <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="25" fontWeight="bold">
                                    Siri HR Consultant
                                </text>

                                <image href={stars} x="32%" y="60%" width="120" height="120" />
                            </svg>
                        </div>
                    </div>

                    <div className='col-md-3 mt-4 d-flex justify-content-center'>
                        <div className='border' style={{ height: "388px", width: "301px" }}>
                            <div className='mt-4'>
                                <p className='text-center p-4'>
                                    "I highly recommend them for any software and hardware projects" </p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="275" viewBox="0 0 331 205" fill="none">

                                <path d="M330.006 16C188.003 149.5 69.0031 -56.4997 0 16V204.445H330.006V16Z" fill="#81BA64" />
                                <defs>
                                    <clipPath id="circleClip">
                                        <circle cx="165" cy="50" r="50" />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#circleClip)">
                                    <rect x="116" y="0" width="100" height="100" fill="white" />
                                    <image href={logo3} x="134" y="-10" width="120" height="120" />
                                </g>
                                <circle cx="165" cy="50" r="50" fill="none" stroke="white" strokeWidth="3" />
                                <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="25" fontWeight="bold">
                                    NPR Bricks
                                </text>
                                <image href={stars} x="32%" y="60%" width="120" height="120" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
}

export default Home;
