import React from "react";
import "../styles/style.css";
import careers from "../images/careers.png";
import job from "../images/job.png";
import Footer from "../components/Footer";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Careers() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="container">
        <div className="row d-flex justify-centent-evenly">
          <div className="col-md-5 mb-5" style={{ marginTop: "100px" }}>
            <h1 className="" style={{ fontSize: "40px" }}>
              Discover Your Calling Build a Fullfilling Career with Us!
            </h1>
            <p className="lead mt-5">
              We are people-centric and highly collaborative. Prioritizing
              longevity, we believe we are on a marathon and not on a sprint, so
              we focus on work-life balance and happiness at the workplace
            </p>
            <br></br>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 d-flex justify-content-left">
            <img src={careers} width="100%"></img>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row ">
          <div className="d-flex flex-column align-items-center">
            <h1 style={{ fontSize: "50px" }}>Our Recent Job Openings</h1>
            <div class="wrap mt-5">
              <div class="search">
                <button className="btn text-secondary">
                  <Search />
                </button>
                <input
                  type="text"
                  class="searchTerm"
                  placeholder=" Search a Job Role"
                />
                <button type="submit" class="searchButton me-3">
                  <p className="text-white">Search</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row d-flex m-2 border">
          <div className="col-md-1 d-flex flex-column align-items-center">
            <div
              className="mt-4 rounded-5"
              style={{ backgroundColor: "#D9D9D9", padding: "10px" }}
            >
              <img src={job} width="100%" height="100%"></img>
            </div>
          </div>
          <div className="col-md-8 mt-4 mb-4">
            <h3>Senior System Architect</h3>
            <p>
              <b>Description :</b> We are seeking an exceptional individual with
              a strategic mindset and deep technical expertise to join our
              innovative team, implementing the architectural vision of our
              systems.
            </p>
            <p>
              <b>Qualification :</b> B.Sc.CS, B.Sc.CT ,MCA
            </p>
            <p>
              <b>Experience :</b> 5 Years{" "}
            </p>
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            <Link
              to="/careerRegistration"
              className="text-decoration-none  menuInput"
              onClick={handleClick}
            >
              {" "}
              <button
                className="btn rounded-2 mt-2 mb-2"
                style={{ backgroundColor: "#26ADB7" }}
              >
                <h5 className="m-1 text-white">Apply Now</h5>
              </button>
            </Link>
          </div>
        </div>

        <div className="row d-flex border m-2">
          <div className="col-md-1 d-flex flex-column align-items-center">
            <div
              className="mt-4 rounded-5"
              style={{ backgroundColor: "#D9D9D9", padding: "10px" }}
            >
              <img src={job} width="100%"></img>
            </div>
          </div>
          <div className="col-md-8 mt-4 mb-4">
            <h3>UI / UX Designer</h3>
            <p>
              <b>Description :</b>We are on the lookout for an exceptionally
              talented individual. You have the power to craft intuitive user
              interfaces that captivate and a profound grasp of user experience
              principles, seize this opportunity to redefine the way people
              interact with digital experiences.
            </p>
            <p>
              <b>Qualification :</b> B.Sc.CS, B.Sc.CT
            </p>
            <p>
              <b>Experience :</b> 0-2 Years{" "}
            </p>
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            <Link
              to="/careerRegistration"
              className="text-decoration-none  menuInput "
              onClick={handleClick}
            >
              <button
                className="btn rounded-2 mt-2 mb-2"
                style={{ backgroundColor: "#26ADB7" }}
              >
                <h5 className="m-1 text-white">Apply Now</h5>
              </button>
            </Link>
          </div>
        </div>
        <div className="row d-flex border m-2">
          <div className="col-md-1 d-flex flex-column align-items-center">
            <div
              className="mt-4 rounded-5"
              style={{ backgroundColor: "#D9D9D9", padding: "10px" }}
            >
              <img src={job} width="100%"></img>
            </div>
          </div>
          <div className="col-md-8 mt-4 mb-4">
            <h3>Intern Software Developer</h3>
            <p>
              <b>Description :</b> We have an exciting opportunity for an Intern
              Software Developer to join our dynamic team. As an intern, you
              will gain valuable hands-on experience on real-world projects and
              collaborating with a talented group of professionals.
            </p>
            <p>
              <b>Qualification :</b> B.Sc.CS, B.Sc.CT, MCA
            </p>
            <p>
              <b>Experience :</b> 0-1 Years{" "}
            </p>
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            <Link
              to="/careerRegistration"
              className="text-decoration-none  menuInput "
              onClick={handleClick}
            >
              <button
                className="btn rounded-2 mt-2 mb-2"
                style={{ backgroundColor: "#26ADB7" }}
              >
                <h5 className="m-1 text-white">Apply Now</h5>
              </button>
            </Link>
          </div>
        </div>
        <div className="row d-flex border m-2">
          <div className="col-md-1 d-flex flex-column align-items-center">
            <div
              className="mt-4 rounded-5"
              style={{ backgroundColor: "#D9D9D9", padding: "10px" }}
            >
              <img src={job} width="100%"></img>
            </div>
          </div>
          <div className="col-md-8 mt-4 mb-4">
            <h3>Senior Software Developer(Full Stack)</h3>
            <p>
              <b>Description :</b> We have an incredible opportunity for a
              Senior Software Developer to join our exceptional team. You will
              lead the development process, collaborating with cross-functional
              teams to design, develop, and deploy robust software applications.
            </p>
            <p>
              <b>Qualification :</b> B.Sc.CS, B.Sc.CT ,MCA{" "}
            </p>
            <p>
              <b>Experience :</b> 5 Years{" "}
            </p>
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            <Link
              to="/careerRegistration"
              className="text-decoration-none  menuInput "
              onClick={handleClick}
            >
              <button
                className="btn rounded-2 mt-2 mb-2"
                style={{ backgroundColor: "#26ADB7" }}
              >
                <h5 className="m-1 text-white">Apply Now</h5>
              </button>
            </Link>
          </div>
        </div>
        {/* <div className='row d-flex border m-2'>
                    <div className='col-md-1 d-flex flex-column align-items-center'>
                        <div className='mt-4 rounded-5' style={{ backgroundColor: "#D9D9D9", padding: "10px" }}>
                            <img src={job} width="100%" ></img>
                        </div>
                    </div>
                    <div className='col-md-8 mt-4 mb-4'>
                        <h3>Admin</h3>
                        <p><b>Description :</b> We are seeking a highly organized and proactive individual person, with exceptional administrative skills and thrive in a fast-paced environment, seize this opportunity to be the backbone of our operations.</p>
                        <p><b>Qualification :</b> MBA (5 Years Experience) </p>
                        <p><b>Experience :</b> 5 Years </p>

                    </div>
                    <div className='col-md-3 d-flex align-items-center justify-content-center'>
                        <Link to='/careerRegistration' className='text-decoration-none  menuInput ' onClick={handleClick}><button className='btn rounded-2 mt-2 mb-2' style={{ backgroundColor: "#26ADB7" }}><h5 className='m-1 text-white'>Apply Now</h5></button></Link>
                    </div>
                </div>
                <div className='row d-flex border m-2'>
                    <div className='col-md-1 d-flex flex-column align-items-center'>
                        <div className='mt-4 rounded-5' style={{ backgroundColor: "#D9D9D9", padding: "10px" }}>
                            <img src={job} width="100%" ></img>
                        </div>
                    </div>
                    <div className='col-md-8 mt-4 mb-4'>
                        <h3>Business Analyst</h3>
                        <p><b>Description :</b> We are in search of a highly skilled and Analytic person . Who possess the ability to unravel intricate business processes and translate them into effective solutions, seize this opportunity to empower organizations with your strategic insights.</p>
                        <p><b>Qualification :</b> MBA</p>
                        <p><b>Experience :</b> 5 Years </p>
                    </div>
                    <div className='col-md-3 d-flex align-items-center justify-content-center'>
                        <Link to='/careerRegistration' className='text-decoration-none  menuInput ' onClick={handleClick}><button className='btn rounded-2 mt-2 mb-2' style={{ backgroundColor: "#26ADB7" }}><h5 className='m-1 text-white'>Apply Now</h5></button></Link>
                    </div>
                </div> */}
      </div>
    </>
  );
}

export default Careers;
