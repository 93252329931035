import React from "react";

import ourStory from "../images/pic6.png";

const OurStories = () => {
  return (
    <div>
      <div className="container ">
        <div className="row ">
          <div className="d-flex flex-column align-items-center">
            <h1 style={{ fontSize: "50px" }}>Our Story</h1>

            <p className="lead">
              <b>"Where every moment has a story to tell"</b>
            </p>
          </div>
        </div>
        <div className="row d-flex justify-centent-evenly">
          <div className="col-md-6">
            <img className="w-100 " src={ourStory} alt="OurStories"></img>
          </div>
          <div className="col-md-6 mt-5">
            <p className="lead" style={{ textAlign: "justify" }}>
              TNXT India Private Limited's journey exemplifies the spirit of
              innovation, resilience, and customer-centricity. Our establishment
              in April 2021 paved the way for strategic business model
              development, seizing opportunities, and garnering positive
              reviews. With market analysis as our guiding compass, we proudly
              achieved a triumphant launch in December 2022. As we move forward,
              TNXT India Private Limited remains committed to pioneering
              excellence, driving innovation, and creating transformative
              solutions for our valued customers.
            </p>
          </div>
        </div>
      </div>
      <div className="row"></div>
    </div>
  );
};

export default OurStories;
