import React from "react";
import pic1 from "../images/pic7.png";
import pic2 from "../images/pic8.png";
import pic3 from "../images/pic9.png";

function OurServices() {
  return (
    <>
      <div className="container" style={{ marginTop: "75px" }}>
        <div className="row">
          <div className="d-flex flex-column align-items-center">
            <h1 style={{ fontSize: "50px" }}>Our Story</h1>
            <p className="lead">
              <b>
                "We believe in Collaboration, Mentoring, Growth and Harmony"
              </b>
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5 pb-5 pt-2">
        <div className="row d-flex justify-content-around">
          <div
            className="col-md-3  d-flex flex-column align-items-center justify-content-center"
            style={{
              background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
              boxShadow:
                "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
            }}
          >
            <img src={pic1} className="m-4" width="150px"></img>
            <p className="text-center fw-bold">IT Consulting</p>
          </div>
          <div
            className="col-md-3 d-flex flex-column align-items-center justify-content-center"
            style={{
              background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
              boxShadow:
                "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
            }}
          >
            <img src={pic2} className="m-4" width="120px"></img>
            <p className="text-center fw-bold">IT Outsourcing</p>
          </div>
          <div
            className="col-md-3 border d-flex flex-column align-items-center justify-content-center"
            style={{
              background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
              boxShadow:
                "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
            }}
          >
            <img src={pic3} className="m-4" width="150px"></img>
            <p className="text-center fw-bold">Software Development</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurServices;
