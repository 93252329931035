import React from "react";
import foundation from "../images/landing page foundation.svg";
import deal from "../images/deal.png";
import identity from "../images/identity.png";
import monitor from "../images/monitor.png";
import banking from "../images/banking.png";
import impact from "../images/impact.png";
import businessman from "../images/businessman.png";
import volunteer from "../images/volunteer.jpg";
import sponser from "../images/sponser.png";
import spreadWord from "../images/spread word.svg";
import "../styles/style.css";

export default function Foundation() {
  return (
    <>
      <div className="container">
        <div className="row mt-4 pb-5">
          <h1 style={{ textAlign: "center", color: "#F69D29" }}>
            STARTING SOON!!!
          </h1>
          <div className="col-md-6">
            <img src={foundation} alt="logo not found" width="90%" />
          </div>
          <div className="col-md-6">
            <h1 className="mt-4" style={{ fontSize: "67px" }}>
              <strong>TNXT Foundation</strong>
            </h1>

            <p className="lead" style={{ textAlign: "justify" }}>
              Welcome to TNXT Foundation, a platform dedicated to connecting
              underprivileged children with compassionate sponsors who can make
              a lasting impact on their lives. Our mission is to bridge the gap
              between those in need and individuals who have the desire to
              support and uplift disadvantaged children. We believe that every
              child deserves access to education, healthcare, and a brighter
              future.
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="d-flex flex-column align-items-center">
            <h1 className="text-center" style={{ fontSize: "50px" }}>
              Our Unique Approach
            </h1>
            <p className="lead text-center">
              <b>
                At TNXT Foundation, we go beyond simple sponsorship. We
                understand the importance of trust, transparency, and long-term
                commitment. That's why we prioritize detailed background
                verification and a thorough screening process for both the
                children and sponsors, ensuring the safety and well-being of all
                participants.
              </b>
            </p>
          </div>
        </div>

        <h1
          className="mt-5"
          style={{
            textAlign: "center",
            fontWeight: "bolder",
            paddingBottom: "30px",
          }}
        >
          How It Works
        </h1>
        <div className="row mb-3">
          <div
            className="col-md-4 "
            style={{ backgroundColor: "rgba(246, 157, 41, 0.4)" }}
          >
            <div className="m-3">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={identity}
                  className="my-4"
                  alt="..."
                  height="70px"
                  width="70px"
                />
              </div>
              <h5 className="card-title text-dark">
                Child Identification and Verification
              </h5>
              <p
                className="card-text mt-3 text-dark"
                style={{ textAlign: "justify" }}
              >
                We collaborate with reputable NGOs, local communities, and
                educational institutions to identify children in need. Our team
                conducts extensive background checks, including visits to their
                homes, schools, and interviews with their families, to ensure
                their eligibility and verify their circumstances.
              </p>
            </div>
          </div>

          <div
            className="col-md-4"
            style={{ backgroundColor: "rgba(38, 173, 183, 0.4)" }}
          >
            <div className="m-4">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={deal}
                  className="my-4"
                  alt="..."
                  height="70px"
                  width="70px"
                />
              </div>

              <h5 className="card-title text-dark">
                Sponsor Registration and Screening
              </h5>
              <p
                className="card-text mt-3 text-dark"
                style={{ textAlign: "justify" }}
              >
                Prospective sponsors go through a meticulous screening process
                to ensure their authenticity and commitment. We verify their
                identity, conduct background checks, and assess their
                willingness and ability to provide consistent support for the
                child they wish to sponsor.
              </p>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{ backgroundColor: "rgba(251, 209, 17, 0.4)" }}
          >
            <div className="m-3">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={businessman}
                  className="my-4"
                  height="70px"
                  width="70px"
                />
              </div>
              <h5 className="card-title text-dark">Personalized Matching</h5>
              <p
                className="card-text mt-3 text-dark"
                style={{ textAlign: "justify" }}
              >
                We prioritize creating meaningful connections between sponsors
                and children. Based on their interests, preferences, and shared
                values, we carefully match sponsors with children who can
                benefit most from their support. We consider factors such as
                location, age, educational needs, and aspirations to foster a
                strong and lasting relationship.
              </p>
            </div>
          </div>
        </div>

        <div className="row mb-3 d-flex justify-content-evenly my-5">
          <div
            className="col-md-4 "
            style={{ backgroundColor: "rgba(151, 214, 119, 0.4)" }}
          >
            <div className="m-3">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={monitor}
                  className="my-4"
                  height="70px"
                  width="70px"
                />
              </div>
              <h5 className="card-title text-dark">
                Ongoing Support and Monitoring
              </h5>
              <p
                className="card-text mt-3 text-dark"
                style={{ textAlign: "justify" }}
              >
                Once a sponsorship is established, we provide ongoing support to
                sponsors and children alike. We facilitate regular
                communication, allowing sponsors to stay updated on the progress
                and well-being of the child they are supporting. We also conduct
                periodic check-ins and evaluations to monitor the child's
                development, academic performance, and overall welfare.
              </p>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{ backgroundColor: "rgba(246, 157, 41, 0.4)" }}
          >
            <div className="m-3">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={banking}
                  className="my-4"
                  height="70px"
                  width="70px"
                />
              </div>
              <h5 className="card-title text-dark">
                Transparent Financial Management
              </h5>
              <p
                className="card-text mt-3 text-dark"
                style={{ textAlign: "justify" }}
              >
                We prioritize transparency in financial transactions to ensure
                that sponsors' contributions are used effectively. Sponsors have
                access to detailed reports on how their funds are being
                utilized, including educational expenses, healthcare needs, and
                other essential support for the sponsored child.
              </p>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{ backgroundColor: "rgba(38, 173, 183, 0.4)" }}
          >
            <div className="m-3">
              <div className="d-flex justify-content-center align-items-center">
                <img src={impact} className="my-4" height="70px" width="70px" />
              </div>
              <h5 className="card-title text-dark">
                Impact Measurement and Reporting
              </h5>
              <p
                className="card-text mt-3 text-dark"
                style={{ textAlign: "justify" }}
              >
                We measure and report the impact of sponsorships, sharing
                success stories and updates with sponsors. This allows sponsors
                to witness firsthand the positive changes they are making in the
                lives of the children they support.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2
            className="mt-5 pt-5"
            style={{
              textAlign: "center",
              fontWeight: "bolder",
              paddingBottom: "30px",
            }}
          >
            How You Can Make a Difference
          </h2>
        </div>

        <div className="row">
          <div className="col-md-6 mt-5 pt-5">
            <h2>Become a Sponsor</h2>
            <p className="lead">
              By sponsoring a child, you can make a direct and meaningful impact
              on their life. Your support can provide them with access to
              education, healthcare, nutrition, and opportunities for personal
              growth.
            </p>
          </div>
          <div className="col-md-6">
            <img src={sponser} alt="logo not found" width="80%" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={volunteer} alt="logo not found" width="100%" />
          </div>
          <div className="col-md-6 mt-5 pt-5">
            <h2>Volunteer</h2>
            <p className="lead">
              If you're passionate about our cause, consider volunteering your
              time and skills. You can contribute by assisting with
              administrative tasks, organizing events, or providing mentorship
              to sponsored children.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-5 pt-5">
            <h2>Spread the Word</h2>
            <p className="lead">
              Help us reach more sponsors and create awareness about the
              importance of supporting underprivileged children. Share our
              mission, success stories, and impact on social media or through
              personal networks.
            </p>
          </div>
          <div className="col-md-6">
            <img src={spreadWord} alt="logo not found" width="80%" />
          </div>
        </div>

        <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
          <p className="lead text-center">
            Join TNXT Foundation today and be a part of a powerful movement that
            transforms lives. Together, we can provide a brighter future for
            underprivileged children. Contact us to learn more about our
            sponsorship program, the screening process, or to express your
            interest in making a difference.
          </p>
        </div>
      </div>
    </>
  );
}
