import { Facebook, Instagram, LinkedIn, LocationCity, Mail, Phone, Place, Twitter } from "@mui/icons-material";
import React from "react";
import tnxt from "../images/tnxt.png";
import facebook from "../images/facebook.png";
import twitter from "../images/twitter.png";
import instagram from "../images/instagram.png";
import linkedin from "../images/linkedin.png";

import '../styles/style.css'
import { Link } from "react-router-dom";

const Footer = () => {
    const handleClick = () => {
        window.scrollTo(0, 0);
    }

    return (
        <footer >
            <div className="container-fluid px-5 " style={{ fontFamily: "Noto Sans" }}>
                <hr style={{ color: "#26ADB7" }}></hr>
                <div className="row pt-5 ">
                    <div className="d-flex justify-content-around">
                    </div>
                    <div className="col-md-4 mb-5">
                        <Link className="Link" to="/" onClick={handleClick} > <img src={tnxt} alt='logo' width={150} /> </Link>
                        <p className="mt-3" style={{ textAlign: "justify" }}> Welcome to TNXT India Private Limited, a leading provider of IT services and solutions. We are a team of experienced professionals who are dedicated to helping our clients succeed through the effective use of technology.</p>
                    </div>
                    <div className="col-md-1">
                    </div>
                    <div className="col-md-3 d-flex flex-column gap-2 mb-5">
                        <h4 className="" >QUICK LINK</h4>
                        <Link className="Link" to="/aboutUs" onClick={handleClick} >About Us</Link>
                        <Link className="Link" to="/ourStory" onClick={handleClick} >Our Story</Link>
                        {/* <Link className="Link" to="/Products" onClick={handleClick} >Product</Link> */}
                        <Link className="Link" to="/careers" onClick={handleClick} >Careers</Link>
                        <Link className="Link" to="/contactUs" onClick={handleClick} >Contact Us</Link>
                    </div>
                    <div className="col-md-4 d-flex flex-column gap-2 text-left mb-5">
                        <h4 className="" >OUR SERVICES</h4>
                        <Link className="Link" to="/itConsulting" onClick={handleClick} >Consulting</Link>
                        <Link className="Link" to="/itOutSourcing" onClick={handleClick}>Outsourching Services</Link>
                        <Link className="Link" to="/softwareDevelopment" onClick={handleClick}>Software Development & Maintanence</Link>
                    </div>
                    {/* <div className="col-md-2 text-left mb-5">

                        <h4 className="">CONNECT US</h4>

                        <div className="social-as d-flex gap-3 mt-3">

                            <li className="list-unstyled" style={{ width: "auto", padding: "5px" }}><a href="https://www.facebook.com/profile.php?id=100088895907639" target="_blank"><img src={facebook} width="100%"></img></a></li>

                            <li className="list-unstyled" style={{ width: "auto", padding: "5px" }}><a href="https://twitter.com/Tnxt22" target="_blank" ><img src={twitter} width="100%"></img></a></li>

                            <li className="list-unstyled" style={{ width: "auto", padding: "5px" }}><a href="https://www.instagram.com/tnxtindia/" target="_blank" ><img src={instagram} width="100%"></img></a></li>

                            <li className="list-unstyled" style={{ width: "auto", padding: "5px" }}><a href="https://www.linkedin.com/in/tnxt-india-it-services-company-09a2bb261/" target="_blank" ><img src={linkedin} width="100%"></img></a></li>

                        </div>




                        <h5 className="">OUR OFFICE</h5>

                        <p className="mb-2" ><LocationCity/> 323, Srivari Srimath, 3rd Floor, No:1045, Avinashi Road, Coimbatore - 641018</p>

                        <p className="mb-2" ><Place/> PSG-STEP Innosphere,

                            PSG college of technology Peelamedu, Coimbatore - 641004</p>

                        <p className="mb-2" ><Phone/> +91 9092228220</p>

                        <p className="mb-2" ><Mail/><a href="mailto:info@t-nxt.com" target="_blank"><span >info@t-nxt.com</span></a></p>

                    </div> */}

                </div>
                <div className="d-flex justify-content-center">
                    <p> &copy; Copyright 2024 | TNXT India Private Limited  |  All Rights Reserved</p>
                </div>
            </div>
        </footer>

    );
}

export default Footer;