import React from "react";
import { LocationCity, Mail, Phone, Place } from "@mui/icons-material";
import contact from "../images/contact.png";
import building from "../images/office-building.png";
import "../styles/style.css";

const Contact = () => {
  return (
    <div className=" container">
      <div className="row mt-5  shadow-sm d-flex justify-content-evenly align-items-center ">
        <div className="col-md-6 mt-5  mb-5">
          <h1 className="mb-3">Contact Us</h1>
          <h4 className="mb-5">
            For general queries, sales and partner ships please drop a Email, We
            will reach you.
          </h4>
          <a
            className="shadow text-center px-5 py-3  text-white "
            style={{ backgroundColor: "#808080", border: "none" }}
            href="mailto:info@t-nxt.com"
          >
            E-Mail Us
          </a>
        </div>
        <div className="col-md-6  d-flex justify-content-center  ">
          <img src={contact} alt="contact" width="80%" />
        </div>
      </div>

      {/* <div>

                <h2 className="text-center mt-5 mb-5 pt-5"> Office Location</h2>

                <div className="row d-flex justify-content-around">

                    <div className="col-md-6 d-flex justify-content-center mb-3">

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.4271575522166!2d76.97459207354825!3d11.00654298915657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859b1ca051167%3A0x7b2a09130e46d954!2sSrivari%20Shrimat!5e0!3m2!1sen!2sin!4v1689229081711!5m2!1sen!2sin" width="400px" height="400px" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="rounded shadow"></iframe>

                    </div>

                    <div className="col-md-6   mb-3" style={{ paddingLeft: "5%" }}>



                        <h4 className="mt-5 ">OUR OFFICE</h4>

                        <p className="mb-2" ><LocationCity /> 323, Srivari Srimath, 3rd Floor, No:1045, Avinashi Road, Coimbatore - 641018</p>

                        <p className="mb-2" ><Place /> PSG-STEP Innosphere,

                            PSG college of technology Peelamedu, Coimbatore - 641004</p>

                        <p className="mb-2" ><Phone /> +91 9092228220</p>

                        <p className="mb-2" ><Mail /><a className="text-dark text-decoration-none" href="mailto:info@t-nxt.com" target="_blank"><span  >info@t-nxt.com</span></a></p>

                    </div>

                </div>

            </div> */}
    </div>
  );
};
export default Contact;
