import React from "react";
import socialMedia from "../images/socialmedia.png";
import kidsQatar from "../images/kids qatar.png";
import kidsqatarProduct from "../images/KidsQatarProduct.png";
import employee from "../images/EmployeeProduct2.png";
import epicX from "../images/epicX.png";
import logistics from "../images/LogisticsProduct.png";
import visitor from "../images/VisitorProduct.png";
import traveldesk from "../images/TravelDeskProduct.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Product = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="container" style={{ marginTop: "75px" }}>
      <div className="row">
        <div className="d-flex flex-column mb-3 align-items-center">
          <h1 style={{ fontSize: "50px" }}>Our Products</h1>
        </div>
      </div>
      <div className="mt-5 mb-5">
        <Carousel responsive={responsive}>
          <div className="d-flex justify-content-center">
            <div
              className="card d-flex align-items-center w-75"
              style={{
                background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
                boxShadow:
                  "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
              }}
            >
              <div className="m-3 d-flex justify-content-center align-items-center">
                <img
                  className="rounded-circle p-2"
                  src={kidsqatarProduct}
                  width="109%"
                  height="100%"
                ></img>
              </div>
              <p>Kids Qatar</p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="card d-flex  align-items-center w-75"
              style={{
                background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
                boxShadow:
                  "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
              }}
            >
              <div className="m-3 d-flex justify-content-center align-items-center">
                <img className="" src={socialMedia} width="95%"></img>
              </div>
              <p>Social Media Analysis</p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="card d-flex  align-items-center w-75"
              style={{
                background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
                boxShadow:
                  "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
              }}
            >
              <div className="m-3 d-flex justify-content-center align-items-center">
                <img
                  className="rounded-circle"
                  src={employee}
                  width="93%"
                ></img>
              </div>
              <p>Employee Management</p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="card d-flex  align-items-center w-75"
              style={{
                background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
                boxShadow:
                  "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
              }}
            >
              <div className="m-3 d-flex justify-content-center align-items-center">
                <img className="product--image" src={epicX} width="95%"></img>
              </div>
              <p>Epic-X</p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="card d-flex  align-items-center w-75"
              style={{
                background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
                boxShadow:
                  "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
              }}
            >
              <div
                className="m-3 d-flex justify-content-center align-items-center rounded-circle"
                style={{ backgroundColor: "white" }}
              >
                <img
                  src={logistics}
                  className=" "
                  style={{ margin: "9px" }}
                  width="90%"
                  height="90%"
                ></img>
              </div>
              <p>Logistics Solutions</p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="card d-flex  align-items-center w-75"
              style={{
                background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
                boxShadow:
                  "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
              }}
            >
              <div
                className="m-3 d-flex justify-content-center align-items-center  rounded-circle"
                style={{ backgroundColor: "white" }}
              >
                <img
                  src={visitor}
                  className=" "
                  style={{ margin: "7px" }}
                  width="90%"
                  height="90%"
                ></img>
              </div>
              <p>Visitor Management</p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="card d-flex  align-items-center w-75"
              style={{
                background: "linear-gradient(135deg, #FFF 0%, #EDEDED 100%)",
                boxShadow:
                  "2.0363636016845703px 2.0363636016845703px 5.090909481048584px 0px rgba(175, 175, 175, 0.90), -2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(255, 255, 255, 0.90), 2.0363636016845703px -2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -2.0363636016845703px 2.0363636016845703px 4.072727203369141px 0px rgba(175, 175, 175, 0.20), -1.0181818008422852px -1.0181818008422852px 2.0363636016845703px 0px rgba(175, 175, 175, 0.50) inset, 1.0181818008422852px 1.0181818008422852px 2.0363636016845703px 0px rgba(255, 255, 255, 0.30) inset",
              }}
            >
              <div
                className="m-3 d-flex justify-content-center align-items-center rounded-circle"
                style={{ backgroundColor: "white" }}
              >
                <img
                  src={traveldesk}
                  className=""
                  style={{ margin: "13px" }}
                  width="90%"
                  height="90%"
                ></img>
              </div>
              <p>Travel Desk Solutions</p>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Product;
