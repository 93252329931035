import React from "react";

import IT_Consulting_logo from "../images/pic5.png";

import { useState } from "react";

import It_Infra_Imgage from "../images/Infra.png";

import legal from "../images/legal.png";

import hr from "../images/pic2.png";

import { TaskAlt } from "@mui/icons-material";

import "../styles/style.css";
import { Link } from "react-router-dom";

export default function ItConsulting() {
  const [activeSection, setActiveSection] = useState(null);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const [view, setReadMore] = useState(false);

  const handleReadMoreClick = () => {
    setReadMore(true);
  };

  const handleReadLessClick = () => {
    setReadMore(false);
  };

  const [show, setReadMoreHr] = useState(false);

  const handleReadHrClick = () => {
    setReadMoreHr(true);
  };

  const handleReadLessHrClick = () => {
    setReadMoreHr(false);
  };

  const [content, setReadMoreLegal] = useState(false);

  const handleReadLegalClick = () => {
    setReadMoreLegal(true);
  };

  const handleReadLessLegalClick = () => {
    setReadMoreLegal(false);
  };

  return (
    <>
      <div className="container d-flex flex-column justify-content-center mt-5">
        <div className="row mt-5 mb-5">
          <div className="col-md-6">
            <h1 style={{ fontSize: "40px" }}>Consulting</h1>

            <h1 style={{ color: "#FBD111", fontSize: "40px" }}>Services</h1>

            <p className="" style={{ textAlign: "justify" }}>
              Our consulting services are designed to help you achieve success
              in your business endeavors. With our expertise in infrastructure
              and security, legal counsel, and human resources guidance, we can
              assist you in making informed decisions that will lead to happy
              outcomes. Trust us to provide you with the best consulting
              services that will help you take your business to the next level.
              Contact us today and let's get started on a journey towards
              success!
            </p>

            <Link
              to="/contactUs"
              onClick={handleClick}
              className="text-decoration-none text-dark"
            >
              {" "}
              <button
                className="btn rounded-2 mt-2 mb-2"
                style={{ backgroundColor: "#26ADB7" }}
              >
                <h5 className="m-1 text-white">Contact Us </h5>
              </button>
            </Link>
          </div>

          <div className="col-md-6">
            <img
              src={IT_Consulting_logo}
              width="100%"
              height="100%"
              alt="logo"
            />
          </div>
        </div>
        <br />
        <br />

        <div className="row mb-5  ">
          <div className="d-flex justify-content-around">
            <div className="col-md-4 mt-2">
              <button
                className="btn btn-lg btncolor border-2"
                style={{
                  borderColor: "#26ADB7",
                  borderRadius: 100,
                  width: "100%",
                }}
                onClick={() => handleButtonClick("legal")}
              >
                Legal
              </button>
            </div>

            <div className="col-md-4 mt-2">
              <button
                className="btn btn-lg btncolor border-2"
                style={{
                  borderColor: "#26ADB7",
                  borderRadius: 100,
                  width: "100%",
                }}
                onClick={() => handleButtonClick("hr")}
              >
                HR
              </button>
            </div>
          </div>

          {/* <div className='col-md-4 mt-2'>

            <button type='button' className='btn btncolor btn-lg border-2' style={{ borderColor: "#26ADB7", borderRadius: 100, width: "100%" }} onClick={() => handleButtonClick('it')}>IT Infrastructure & Security</button>

          </div> */}
        </div>

        {activeSection === "hr" && (
          <div>
            <div className="row">
              <div className="col-md-6">
                <h2>HR Consulting</h2>
                <br></br>
                <p className="lead" style={{ textAlign: "justify" }}>
                  Welcome to TNXT India, your trusted partner for HR consulting
                  and Payroll management services. We specialize in providing
                  strategic HR solutions that empower businesses to optimize
                  their human capital, drive employee engagement, and achieve
                  organizational success. With our experienced team of HR
                  experts, we offer tailored consulting services to meet your
                  unique HR needs, regardless of your industry or company size.
                  Also, We understand that managing payroll is a critical and
                  time-consuming task for businesses of all sizes. With our
                  comprehensive payroll services, we help streamline your
                  payroll processes, ensure accurate and timely payments, and
                  provide peace of mind knowing that your employees'
                  compensation is handled efficiently.
                </p>

                <button
                  type="button"
                  className="btn rounded-2 mt-2 mb-2"
                  style={{ backgroundColor: "#26ADB7" }}
                  onClick={() => handleReadHrClick("view")}
                >
                  <h5 className="m-1 text-white">Read More</h5>
                </button>
              </div>

              <div className="col-md-6">
                <img src={hr} alt="Image not found" width="100%" />
              </div>
            </div>
            {show && (
              <>
                <div className="col">
                  <div className="row-md-3">
                    <p className="lead" style={{ textAlign: "justify" }}>
                      <b className="fw-bold">HR Strategy and Planning:</b>{" "}
                      Develop a comprehensive HR strategy aligned with your
                      organization's goals and objectives. Our consultants work
                      closely with you to assess your current HR practices,
                      identify gaps, and create a roadmap for optimizing your HR
                      function. We help you design effective HR policies,
                      streamline processes, and implement performance management
                      systems to foster a high-performance culture.
                    </p>
                    <br />

                    <p className="lead" style={{ textAlign: "justify" }}>
                      <b className="fw-bold">
                        Talent Acquisition and Recruitment:{" "}
                      </b>
                      Attracting and retaining top talent is crucial for your
                      organization's success. We assist in developing a tailored
                      recruitment strategy, including job profiling, candidate
                      sourcing, and selection processes. Our consultants can
                      also help you enhance your employer brand, develop
                      effective interviewing techniques, and implement applicant
                      tracking systems for a streamlined and efficient
                      recruitment process.
                    </p>
                    <br />
                  </div>

                  <div className="row-md-3">
                    <p className="lead" style={{ textAlign: "justify" }}>
                      <b className="fw-bold">Compensation and Benefits: </b>{" "}
                      Ensure your compensation and benefits programs are
                      competitive and aligned with industry standards. We offer
                      expert advice on job evaluation, salary benchmarking, and
                      the design of reward systems. Our consultants can assist
                      in developing flexible benefits packages, incentives, and
                      recognition programs that motivate employees and enhance
                      their overall satisfaction.
                    </p>
                    <br />

                    <p className="lead" style={{ textAlign: "justify" }}>
                      <b className="fw-bold">
                        Employee Relations and Engagement:
                      </b>{" "}
                      Foster positive employee relations and a supportive work
                      environment. Our HR consultants provide guidance on
                      employee engagement initiatives, employee satisfaction
                      surveys, and communication strategies. We assist in
                      developing employee handbooks, policies, and procedures to
                      promote fair treatment, diversity, and inclusion within
                      your organization.
                    </p>
                    <br />
                  </div>

                  <div className="row-md-3">
                    <p className="lead" style={{ textAlign: "justify" }}>
                      <b className="fw-bold">
                        HR Compliance and Legal Support:
                      </b>
                      Stay compliant with employment laws and regulations. Our
                      consultants ensure your HR practices adhere to local labor
                      laws and regulations. We provide guidance on employee
                      contracts, workplace policies, and disciplinary procedures
                      to minimize legal risks and maintain a productive and
                      legally sound work environment.
                    </p>
                    <br />

                    <div className="row-md-3">
                      <p className="lead" style={{ textAlign: "justify" }}>
                        <b className="fw-bold">Payroll Processing:</b>
                        Our team of payroll experts takes care of all aspects of
                        payroll processing, from calculating wages and salaries
                        to handling deductions and withholdings. We ensure
                        accurate calculations based on employee hours, overtime,
                        bonuses, commissions, and any other applicable factors.
                        With our advanced payroll software, we efficiently
                        manage payroll cycles, including frequency and schedules
                        that align with your business needs.
                      </p>

                      <br />
                    </div>
                  </div>

                  <div className="col">
                    <div className="row-md-3">
                      <p className="lead" style={{ textAlign: "justify" }}>
                        <b className="fw-bold">Payroll Processing:</b> Our team
                        of payroll experts takes care of all aspects of payroll
                        processing, from calculating wages and salaries to
                        handling deductions and withholdings. We ensure accurate
                        calculations based on employee hours, overtime, bonuses,
                        commissions, and any other applicable factors. With our
                        advanced payroll software, we efficiently manage payroll
                        cycles, including frequency and schedules that align
                        with your business needs.
                      </p>
                      <br />

                      <p className="lead" style={{ textAlign: "justify" }}>
                        <b className="fw-bold">Tax Compliance:</b> Managing tax
                        compliance can be complex and time-sensitive. We stay up
                        to date with the latest tax regulations and ensure
                        accurate calculation and timely submission of payroll
                        taxes. Our experts handle tax deductions, filings, and
                        reporting, including federal, state, and local taxes. We
                        also assist with year-end tax documentation, such as W-2
                        and 1099 forms, to ensure compliance with tax laws and
                        regulations.
                      </p>
                      <br />
                    </div>

                    <div className="row-md-3">
                      <p className="lead" style={{ textAlign: "justify" }}>
                        <b className="fw-bold">
                          Time and Attendance Integration:
                        </b>{" "}
                        Integrate your time and attendance systems with our
                        payroll management software for seamless and accurate
                        payroll processing. Our system can be easily integrated
                        with various time tracking solutions, allowing for
                        automatic import of employee hours and attendance data.
                        This reduces manual data entry, minimizes errors, and
                        ensures accurate payroll calculations.
                      </p>
                      <br />

                      <p className="lead" style={{ textAlign: "justify" }}>
                        <b className="fw-bold">Compliance and Reporting:</b>Stay
                        compliant with labor laws and regulations by relying on
                        our expertise in payroll compliance. We assist with wage
                        and hour laws, overtime regulations, leave management,
                        and other relevant legal requirements. Our payroll
                        management system generates comprehensive reports, such
                        as payroll summaries, tax filings, and labor cost
                        analysis, providing valuable insights into your payroll
                        expenses.
                      </p>
                      <br />

                      <p className="lead" style={{ textAlign: "justify" }}>
                        <b className="fw-bold">Compliance and Reporting:</b>Stay
                        compliant with labor laws and regulations by relying on
                        our expertise in payroll compliance. We assist with wage
                        and hour laws, overtime regulations, leave management,
                        and other relevant legal requirements. Our payroll
                        management system generates comprehensive reports, such
                        as payroll summaries, tax filings, and labor cost
                        analysis, providing valuable insights into your payroll
                        expenses.
                      </p>
                      <br />
                    </div>

                    <div className="row-md-3">
                      <p className="lead" style={{ textAlign: "justify" }}>
                        <b className="fw-bold">
                          Dedicated Support and Security:
                        </b>{" "}
                        Our team of payroll professionals is committed to
                        providing exceptional customer support. We offer
                        dedicated assistance for any payroll-related inquiries,
                        resolving issues promptly and accurately. Additionally,
                        we prioritize data security and confidentiality. Our
                        payroll management system employs robust security
                        measures, including data encryption, secure servers, and
                        strict access controls, to protect your sensitive
                        employee information.
                      </p>
                      <br />

                      <button
                        type="button"
                        className="btn rounded-2 mt-2 mb-2"
                        style={{ backgroundColor: "#26ADB7" }}
                        onClick={() => handleReadLessHrClick("hide")}
                      >
                        <h5 className="m-1 text-white">Read Less</h5>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {activeSection === "legal" && (
          <div>
            <div className="row">
              <div className="col-md-6">
                <h2>
                  Legal Consulting (
                  <b style={{ color: "#F69D29" }}>Starting Soon!</b>)
                </h2>
                <br></br>

                <p className="lead" style={{ textAlign: "justify" }}>
                  Welcome to TNXT India, your trusted partner for legal
                  consulting services. We specialize in providing comprehensive
                  and strategic legal guidance to businesses, organizations, and
                  individuals. With our experienced team of legal experts, we
                  offer tailored solutions to help you navigate complex legal
                  challenges and achieve your objectives. Whether you need
                  assistance with compliance, contracts, intellectual property,
                  or other legal matters, we are here to support you.
                </p>

                <button
                  type="button"
                  className="btn rounded-2 mt-2 mb-2"
                  style={{ backgroundColor: "#26ADB7" }}
                  onClick={() => handleReadMoreClick("view")}
                >
                  <h5 className="m-1 text-white">Read More</h5>
                </button>
              </div>

              <div className="col-md-6">
                <img src={legal} alt="Image not found" width="100%" />
              </div>
            </div>
            {view && (
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <h3 className="mt-5 ">
                      <b className="fw-bolder ">
                        {" "}
                        Why Choose TNXT India for Legal Consulting?
                      </b>
                    </h3>

                    <p className="lead" style={{ textAlign: "justify" }}>
                      <b className="fw-bold"> Expertise and Experience:</b> Our
                      team of experienced legal professionals brings a wealth of
                      knowledge across various areas of law, ensuring you
                      receive reliable and comprehensive legal
                      guidance.Customized Solutions: We understand that each
                      client's needs are unique. We provide tailored solutions
                      that are specifically designed to address your legal
                      challenges and meet your objectives.
                      <br />
                    </p>

                    <p className="lead" style={{ textAlign: "justify" }}>
                      <b className="fw-bold">
                        Responsive and Collaborative Approach:
                      </b>{" "}
                      We value open communication and collaboration with our
                      clients. We work closely with you to understand your goals
                      and provide practical, proactive legal advice.Industry and
                      Jurisdiction Knowledge: Our consultants stay up to date
                      with the latest legal developments and trends in your
                      industry and jurisdiction, ensuring that our advice aligns
                      with the most current legal requirements and best
                      practices.
                    </p>
                    <br />

                    <p className="lead" style={{ textAlign: "justify" }}>
                      <b className="fw-bold"> Client-Focused Service:</b> We
                      prioritize building long-term relationships with our
                      clients and strive to deliver exceptional service that
                      exceeds your expectations.
                    </p>
                    <br />

                    <i>
                      Contact TNXT India today to discuss your legal consulting
                      needs. Let us partner with you to provide the trusted
                      legal guidance and support that your business deserves.
                    </i>
                  </div>

                  <button
                    type="button"
                    className="btn rounded-2 mt-2 mb-2"
                    style={{ backgroundColor: "#26ADB7" }}
                    onClick={() => handleReadLessClick("hide")}
                  >
                    <h5 className="m-1 text-white">Read Less</h5>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {/* {activeSection === 'it' && (
          <div>

            <div className='row'>

              <div className='col-md-6'>

                <h2>IT Infrastructure/Security Solutions </h2><br></br>

                <p className='lead' style={{ textAlign: 'justify' }}>Welcome to TNXT India Private Limited, your trusted partner for IT Infrastructure consulting. With our expertise and cutting-edge solutions, we empower businesses to build robust and scalable IT environments that drive growth and efficiency. Whether you're a small startup or a large enterprise, we offer tailored consulting services to meet your unique IT infrastructure needs.</p>

                <li className='list-unstyled'><TaskAlt className='text-success'></TaskAlt> IT Infrastructure Assessment</li>

                <li className='list-unstyled'><TaskAlt className='text-success'></TaskAlt> IT Infrastructure Design and Planning</li>

                <li className='list-unstyled'><TaskAlt className='text-success'></TaskAlt> Network Infrastructure</li>

                <li className='list-unstyled'><TaskAlt className='text-success'></TaskAlt> Cloud Solutions</li>

                <li className='list-unstyled'><TaskAlt className='text-success'></TaskAlt> Data Center Solutions</li>

                <li className='list-unstyled'><TaskAlt className='text-success'> </TaskAlt> Security and Compliance</li>

                <li className='list-unstyled'><TaskAlt className='text-success'> </TaskAlt> IT Infrastructure Management</li>

                <button type="button" className='btn rounded-2 mt-2 mb-2' style={{ backgroundColor: "#26ADB7" }} onClick={() => handleReadLegalClick('content')}><h5 className='m-1 text-white'>Read More</h5></button>

              </div>




              <div className='col-md-6'>

                <img src={It_Infra_Imgage} alt='Image not found' width="100%" />

              </div>

            </div>
            {content && (

              <>

                <div className='row'>

              

                  <div className='col-md-12'>

                    <h2>Why to Choose TNXT IT Infra Consulting</h2>

                    <p className='lead' style={{ textAlign: 'justify' }}><b className='fw-bold'>Expertise:</b>  Our team comprises highly skilled IT professionals with extensive experience in designing and managing complex IT infrastructures across various industries.</p>




                    <p className='lead' style={{ textAlign: 'justify' }}><b className='fw-bold'>Tailored Solutions:</b> We understand that every organization has unique requirements. Hence, we deliver customized solutions that align with your specific business objectives and budget.</p>




                    <p className='lead' style={{ textAlign: 'justify' }}><b className='fw-bold'>Vendor Neutrality:</b> We maintain vendor neutrality, enabling us to recommend and implement the best solutions from a wide range of leading technology providers.</p>




                    <p className='lead' style={{ textAlign: 'justify' }}><b className='fw-bold'>Focus on Security: </b>With the increasing importance of data security, we prioritize implementing robust security measures to protect your sensitive information.</p>




                    <p className='lead' style={{ textAlign: 'justify' }}><b className='fw-bold'>Proactive Support:</b> Our dedicated support team is available round the clock to address any issues or concerns promptly, ensuring minimal downtime and maximum productivity.</p><br />




                    <button type="button" className='btn rounded-2 mt-2 mb-2' style={{ backgroundColor: "#26ADB7" }} onClick={() => handleReadLessLegalClick('hide')}><h5 className='m-1 text-white'>Read Less</h5></button>

                  </div>



                </div>

              </>




            )}

          </div>

        )} */}
      </div>
    </>
  );
}
