import React from "react";

import outsourcing from "../images/IT OUTSOURCING.svg";

import { useState } from "react";

import hardwaresupport from "../images/HARDWARE SUPPORT.svg";

import softwaresupport from "../images/SOFTWARE SUPPORT.svg";

import remotesupport from "../images/IT_Remote_Support.svg";

import "../styles/style.css";
import { Link } from "react-router-dom";

export default function ItOutSourcing() {
  const [activeSection, setActiveSection] = useState(null);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <div className="container d-flex flex-column justify-content-center ">
        <div className="row  mb-5">
          <div className="col-md-6 mt-5 pt-5 mb-5">
            <h1 style={{ fontSize: "40px" }}>OutSourcing</h1>

            <h1 style={{ color: "#FBD111", fontSize: "40px" }}>Services</h1>

            <p className="" style={{ textAlign: "justify" }}>
              Outsourcing your IT services can be a game-changer for your
              business. By partnering with us, you can rest assured that all
              your IT needs will be taken care of by experts in the field. We
              offer a wide range of services, including remote IT services,
              hardware sales and support, and software sales and support. Our
              team is dedicated to providing exceptional service and support to
              ensure that your business runs smoothly. Don't hesitate to contact
              us today to learn more about how we can help take your business to
              the next level!
            </p>

            <Link
              to="/contactUs"
              onClick={handleClick}
              className="text-decoration-none text-dark"
            >
              {" "}
              <button
                className="btn rounded-2 mt-2 mb-2"
                style={{ backgroundColor: "#26ADB7" }}
              >
                <h5 className="m-1 text-white">Contact Us </h5>
              </button>
            </Link>
          </div>

          <div className="col-md-6">
            <img src={outsourcing} width="100%" height="100%" alt="logo" />
          </div>
        </div>
        <br />
        <br />

        <div className="row mb-5 d-flex justify-content-around">
          {/* <div className='col-md-4 mt-2'>

            <button type='button' className='btn btncolor btn-lg border-2' style={{borderColor:"#26ADB7",borderRadius:100,width:350}}onClick={() => handleButtonClick('remort it support')}>Remote IT Support</button>

          </div> */}

          <div className="col-md-4 mt-2">
            <button
              className="btn btn-lg btncolor border-2"
              style={{ borderColor: "#26ADB7", borderRadius: 100, width: 350 }}
              onClick={() => handleButtonClick("hardware")}
            >
              Hardware Sales/Support
            </button>
          </div>

          <div className="col-md-4 mt-2">
            <button
              className="btn btn-lg btncolor border-2"
              style={{ borderColor: "#26ADB7", borderRadius: 100, width: 350 }}
              onClick={() => handleButtonClick("software")}
            >
              Software Sales/Support
            </button>
          </div>
        </div>

        {/* {activeSection === 'remort it support' && (

                   

          <div className='row'>

            <div className='col-md-6 mt-5'>

             

              <h2>Remote IT Support</h2><br></br>

              <p className='lead'style={{textAlign:'justify'}}><b className='fw-bold'>Remote support:</b> We offer remote support options to help you resolve issues quickly and efficiently, without the need for an onsite visit.</p>




              <p className='lead'style={{textAlign:'justify'}}><b className='fw-bold'>Onsite support:</b> When necessary, we can dispatch a technician to your location to provide hands-on support and resolve any hardware or software issues you may be experiencing.</p>

             

             

            </div>

            <div className='col-md-6'>

                 <img src={remotesupport} alt='Image not found' width="100%"/>

            </div>

          </div>

         

        )} */}

        {activeSection === "hardware" && (
          <div className="row">
            <div className="col-md-6 mt-5">
              <h2>Hardware Sales/Support</h2>
              <br></br>

              <p className="lead" style={{ textAlign: "justify" }}>
                <b className="fw-bold">Hardware repair and maintenance:</b> We
                can troubleshoot and repair a wide range of hardware issues,
                including computer hardware, servers, networking equipment, and
                more.
              </p>
            </div>

            <div className="col-md-6">
              <img src={hardwaresupport} alt="Image not found" width="100%" />
            </div>
          </div>
        )}

        {activeSection === "software" && (
          <div className="row">
            <div className="col-md-6 mt-5">
              <h2>Software Sales/Support</h2>
              <br></br>

              <p className="lead" style={{ textAlign: "justify" }}>
                <b className="fw-bold">Software support:</b> We offer software
                support for a variety of platforms and applications, including
                operating systems, productivity software, and specialized
                business applications.
              </p>
            </div>

            <div className="col-md-6">
              <img src={softwaresupport} alt="Image not found" width="100%" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
