import React from "react";
import softwareMain from "../images/SOWTWARE DEVELOPMENT.svg";
import { useState } from "react";

// import It_Infra_Imgage from '../images/pic4.png'
import desktop from "../images/Desktop Development.svg";
import web from "../images/Web Devlopment.svg";
import mobile from "../images/Mobile app devlopment.svg";
import "../styles/style.css";
import { Link } from "react-router-dom";

export default function SoftwareDev() {
  const [activeSection, setActiveSection] = useState(null);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <div className="container d-flex flex-column justify-content-center ">
        <div className="row mt-5 mb-5">
          <div className="col-md-5">
            <h1 style={{ fontSize: "36px" }}>
              Software Development & Maintanence
            </h1>

            <h1 style={{ color: "#FBD111", fontSize: "36px" }}>Services</h1>

            <p className="" style={{ textAlign: "justify" }}>
              Software development and maintenance are crucial aspects of any
              business or organization that wants to stay ahead of the curve.
              With our team of experienced developers, we can help you create
              desktop applications, web applications, and mobile applications
              that are tailored to your specific needs. We pride ourselves on
              crafting unique solutions that will help your business thrive.
              Don't let outdated software hold you back any longer – To learn
              how we can assist you in growing your company, get in touch with
              us right away.
            </p>

            <Link
              to="/contactUs"
              onClick={handleClick}
              className="text-decoration-none text-dark"
            >
              {" "}
              <button
                className="btn rounded-2 mt-2 mb-2"
                style={{ backgroundColor: "#26ADB7" }}
              >
                <h5 className="m-1 text-white">Contact Us </h5>
              </button>
            </Link>
          </div>

          <div className="col-md-7">
            <img src={softwareMain} width="100%" height="100%" alt="logo" />
          </div>
        </div>
        <br />
        <br />

        <div className="row mb-5">
          <div className="col-md-4 mt-2">
            <button
              type="button"
              className="btn btncolor btn-lg border-2"
              style={{ borderColor: "#26ADB7", borderRadius: 100, width: 350 }}
              onClick={() => handleButtonClick("desktop")}
            >
              Desktop Application
            </button>
          </div>

          <div className="col-md-4  mt-2">
            <button
              className="btn btn-lg btncolor border-2"
              style={{ borderColor: "#26ADB7", borderRadius: 100, width: 350 }}
              onClick={() => handleButtonClick("web")}
            >
              Web Application
            </button>
          </div>

          <div className="col-md-4 mt-2">
            <button
              className="btn btn-lg btncolor border-2"
              style={{ borderColor: "#26ADB7", borderRadius: 100, width: 350 }}
              onClick={() => handleButtonClick("mobile")}
            >
              Mobile Application
            </button>
          </div>
        </div>

        {activeSection === "desktop" && (
          <div className="row">
            <div className="col-md-6 mt-5">
              {/* Display HR content here */}

              <h3>Why Choose Desktop Application ?</h3>
              <br></br>

              <p className="lead" style={{ textAlign: "justify" }}>
                We offer crafting stellar desktop applications tailored to your
                unique development needs,our team of experts is well-versed in
                creating robust operating systems, productivity software, and
                specialized business applications. With our expertise, we ensure
                your applications are designed and developed to run seamlessly
                and efficiently, empowering you to achieve your development
                goals with eminence.
              </p>
            </div>

            <div className="col-md-6 ">
              <img src={desktop} alt=" not found" width="90%" />
            </div>
          </div>
        )}

        {activeSection === "web" && (
          <div className="row">
            <div className="col-md-6">
              <img src={web} alt=" not found" width="100%" />
            </div>

            <div className="col-md-6 mt-5">
              {/* Display Legal content here */}

              <h3>Why Choose Web Application ?</h3>
              <br></br>

              <p className="lead" style={{ textAlign: "justify" }}>
                Ignite your digital success with our bespoke web applications
                that cater precisely to your unique needs.Harnessing our diverse
                expertise in technologies and frameworks, we create resilient
                e-commerce powerhouses, adaptive business websites, and
                cutting-edge web-based tools. Our meticulous craftsmanship
                guarantees visually captivating applications that seamlessly
                captivate users on any device or browser. Join forces with us to
                unlock optimized web solutions that empower your online
                presence, propelling you towards unmatched achievements.
              </p>
            </div>
          </div>
        )}

        {activeSection === "mobile" && (
          <div className="row">
            <div className="col-md-6 mt-5">
              {/* Display IT Infrastructure/Security Solutions content here */}

              <h3>Why Choose Mobile Application ?</h3>
              <br></br>

              <p className="lead" style={{ textAlign: "justify" }}>
                Unleash the power of innovation with our team of mobile
                application wizards! We specialize in creating captivating,
                cutting-edge mobile applications that are meticulously tailored
                to meet your unique development needs. With our expertise
                spanning a wide range of mobile technologies and frameworks, we
                craft robust e-commerce platforms, dynamic business apps, and
                groundbreaking mobile tools. Our meticulous attention to detail
                ensures visually stunning applications that seamlessly adapt to
                different devices and platforms. Partner with us to unlock
                optimized mobile solutions that elevate your digital presence
                and propel you towards unparalleled success in the palm of your
                hand!
              </p>
            </div>

            <div className="col-md-6">
              <img src={mobile} alt=" not found" width="100%" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
