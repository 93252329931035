import logo from "../images/logotnxtlogin.png";
import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";

const Login = () => {
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [UserValue,setUserValue] =useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Perform form validation
    let validationErrors = 0;

    if (email.trim() === "") {
      toast.error("email is required!", { position: "top-center" });
      validationErrors = validationErrors + 1;
    } else if (!email.includes("@")) {
      toast.warning("includes @ in your email!", { position: "top-center" });
      validationErrors = validationErrors + 1;
    } else if (password.trim() === "") {
      toast.error("Password is required", { position: "top-center" });
      validationErrors = validationErrors + 1;
    } else if (password.length < 8) {
      toast.warning("Password must be at least 8 characters", {
        position: "top-center",
      });
      validationErrors = validationErrors + 1;
    } else if (!/\d/.test(password)) {
      toast.warning("Password must contain at least one digit", {
        position: "top-center",
      });
      validationErrors = validationErrors + 1;
    } else if (!/[a-zA-Z]/.test(password)) {
      toast.warning("Password must contain at least one letter", {
        position: "top-center",
      });
      validationErrors = validationErrors + 1;
    }

    // Submit the form if there are no errors
    if (validationErrors === 0) {
      console.log(email, password);
      try {
        const role = "user";
        const data = await fetch(`http://localhost:8010/api/login/${role}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        });

        const res = await data.json();
        console.log(res);

        if (res.status === 201) {
          localStorage.setItem("usersdatatoken", res.result.token);
          setUsername("");
          setPassword("");
          window.scrollTo(0, 0);
          window.location.href = "/";
        } else if (res.status === 401) {
          Swal.fire({
            icon: "error",
            title: res.error,
            text: "Please Enter Valid Data!",
          });
        } else if (res.status === 422) {
          Swal.fire({
            icon: "info",
            title: res.error,
            text: "Please Enter Valid Data!",
          });
        }
      } catch {
        Swal.fire({
          icon: "info",
          title: "502 Bad Gateway",
        });
      }
    }
  };

  return (
    <>
      <div className="wrapper d-flex align-items-center justify-content-center text-center w-100">
        <div
          className="login"
          style={{ width: "auto", color: "black", padding: 50 }}
        >
          <img
            src={logo}
            alt="Logo"
            width="300px"
            height="250px"
            style={{ marginBottom: "-20%", marginTop: "-10%" }}
          />
          <h2 style={{ fontFamily: "segoeui" }} className="mb-3">
            <b>Login Page</b>
          </h2>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-2">
              <input
                type="email"
                class="form-control rounded"
                placeholder="User Email"
                aria-label="default input example"
                value={email}
                onChange={(e) => setUsername(e.target.value)}
              ></input>
            </div>
            <br />

            <div className="form-group mb-2">
              <input
                type="password"
                className="form-control rounded"
                placeholder="Password"
                aria-label="default input example"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="btn btn-success rounded w-25 p-1 my-2"
            >
              Login
            </button>
            <br />

            <p>
              Don't have an account yet?{" "}
              <Link to={"/register"} className="text-primary">
                Register
              </Link>
            </p>
            {/* <NavLink to={'/forgetpassword'} className="text-primary">Forgot password!</NavLink> */}
          </form>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default Login;
