import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import tnxt from "../images/tnxt.png";
import { Link } from "react-router-dom";
import "../styles/style.css";

const NavBar = () => {
  const handleLogout = async (event) => {
    event.preventDefault();
    localStorage.setItem("usersdatatoken", "");
    window.location.reload();
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const user = localStorage.getItem("usersdatatoken");
  console.log("user", user);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" sticky="top" className="bg-white shadow-sm ">
        <Container fluid>
          <Navbar.Brand as={Link} to="/" onClick={handleClick}>
            <img src={tnxt} alt="logo" width={100} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="justify-content-end flex-grow-1 pe-3 gap-3 ps-4"
              style={{ fontSize: "20px", color: "black" }}
            >
              <NavDropdown
                title="Company"
                id="navbarScrollingDropdown"
                className="menuInput "
                style={{ color: "black" }}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/aboutUs"
                  onClick={handleClick}
                  className=" menuInput "
                >
                  About us
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/ourStory"
                  onClick={handleClick}
                  className="menuInput"
                >
                  Our stories
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/contactUs"
                  onClick={handleClick}
                  className="menuInput"
                >
                  Contact Us
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item
                  as={Link}
                  to="/careers"
                  onClick={handleClick}
                  className="menuInput"
                >
                  Careers
                </NavDropdown.Item>
              </NavDropdown>

              {/*<Link
                to="/products"
                onClick={handleClick}
                className="nav-link menuInput"
              >
                Product
  </Link>*/}

              <NavDropdown
                title="Services"
                id="navbarScrollingDropdown"
                className="menuInput"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/itConsulting"
                  onClick={handleClick}
                  className=" menuInput "
                >
                  Consulting
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/itOutSourcing"
                  onClick={handleClick}
                  className=" menuInput "
                >
                  Outsourcing
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/softwareDevelopment"
                  onClick={handleClick}
                  className="menuInput"
                >
                  Software Development
                </NavDropdown.Item>
              </NavDropdown>

              {/* <Link
                to="/foundation"
                onClick={handleClick}
                className="nav-link menuInput"
              >
                TNXT Foundation
              </Link> */}
            </Nav>
            {/* <Form className="d-flex">
              {!user ? (
                <>
                  <Link to="/login" className="nav-link">
                    <Button
                      className="mx-1 "
                      style={{
                        color: "#26ADB7",
                        backgroundColor: "transparent",
                        borderColor: "#26ADB7",
                      }}
                    >
                      Sign In
                    </Button>
                  </Link>

                  <Link to="/register" className="nav-link">
                    <Button
                      className="mx-1 "
                      style={{
                        color: "#26ADB7",
                        backgroundColor: "transparent",
                        borderColor: "#26ADB7",
                      }}
                    >
                      Sign Up
                    </Button>
                  </Link>
                </>
              ) : (
                <Link to="/" className="nav-link">
                  <Button
                    className="mx-1 "
                    onClick={handleLogout}
                    style={{
                      color: "#26ADB7",
                      backgroundColor: "transparent",
                      borderColor: "#26ADB7",
                    }}
                  >
                    LogOut
                  </Button>
                </Link>
              )}
            </Form> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
