import React from "react";
import Swal from 'sweetalert2';
import { Link, useParams } from "react-router-dom";


const MailVerified = () =>{
    const {status} = useParams();
    const {role} = useParams();
    if(status)
    {
        Swal.fire({
            icon:"success",
            title: "Register Successfully! 😃",
            text: `Mail_Id is Verified.`
        });
    }
    else if(status){
        Swal.fire({
            icon:"error",
            title: "Register Failed!",
            text: `Mail_Id is Not-Verified.`
        });
    }    
    
    return(
        <section style={{width:"100%" ,height:"200px",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div  style={{fontFamily:"'Times New Roman', Times, serif"}}>
                <h6 className="text-center">Click here following link to redirect the page....{role === "user"?<Link to="/login" className="text-primary">Login</Link>  :<Link to="/">SignIn</Link> }</h6>
            </div>   
        </section>
    )
}

export default MailVerified;